import React from "react";

function MainPage() {
    return (
        <div className="text-center text-black main-page">
            <div className="pb-12 top">
                <div name="navigation" className="navigation">
                    <img src="/profile.png" className="h-full rounded-full" />
                    <div className="nav-links text-white font-semibold">
                        {/* <a href="https://patreon.com/dasubot" target="_blank" className="hover:underline cursor-pointer">Premium</a> */}
                        <a href="https://docs.dasu.gifts" target="_blank" className="hover:underline cursor-pointer">Docs</a>
                        <a href="https://discord.gg/HH9VCxe9uj" target="_blank" className="hover:underline cursor-pointer">Support</a>
                        <a href="https://docs.dasu.gifts/partners" className="hover:underline cursor-pointer">Partners</a>
                    </div>
                </div>
        
        
                <div id="main" name="main" className="mt-10 text-white">
                    <h1 className="text-6xl opacity-20 transform translate-y-5 font-bold align-middle"><span className="opacity-0">Gve</span>Faster</h1>
                    <h1 className="text-6xl font-bold align-middle">Give Better</h1>
                    <h1 className="text-6xl opacity-20 transform -translate-y-5 font-bold mb-2 align-middle"><span className="opacity-0">lllllve</span> Easier</h1>
                    <p className="font-normal text-lg">
                        Utilize powerful rules and control your giveaways.<br></br>No limitations. Give how you want.
                    </p>
        
                    <a href="https://discord.com/api/oauth2/authorize?client_id=525748238255390721&permissions=322785&scope=bot%20applications.commands" target="_blank" className="block bg-white text-black font-bold transition duration-200 shadow hover:shadow-2xl ease-in-and-out rounded-lg mx-auto mt-16 px-2 py-4 hover:text-red-800 text-xl font-sans w-60">
                        <i className="fas fa-gifts"></i> Start giving
                    </a>
                </div>
            </div>
        
            <div className="feature flex-col sm:flex-row text-center sm:text-left">
                <div className="w-full sm:w-1/2 p-4 sm:p-8">
                    <img className="rounded-lg shadow shadow-2xl mx-auto" src="/new.png" />
                </div>
                <div className="w-full sm:w-1/2 sm:p-8 feature-header">
                    <h1 className="font-bold text-5xl mb-3">
                        Create in seconds
                    </h1>
                    <p className="font-semibold text-lg">
                        Dasu's interactive menus make it easy to create the most complicated giveaways as efficiently as possible.
                    </p>
                </div>
            </div>
        
            <div className="ofeature flex-col sm:flex-row-reverse text-center sm:text-left">
                <div className="w-full sm:w-1/2 p-4 sm:p-8">
                    <img className="rounded-lg shadow shadow-2xl mx-auto" src="/requirements.png" />
                </div>
                <div className="w-full sm:w-1/2 sm:p-8 feature-header">
                <h1 className="font-bold text-5xl mb-3">
                    Powerful requirements
                </h1>
                <p className="font-semibold text-lg">
                    Limit entries exactly how you want with a series of rules.
                </p>
                </div>
            </div>
        
            <div className="feature flex-col sm:flex-row text-center sm:text-left">
                <div className="w-full sm:w-1/2 p-4 sm:p-8">
                    <img className="rounded-lg shadow shadow-2xl mx-auto" src="/leaderboard.png" />
                </div>
                <div className="w-full sm:w-1/2 sm:p-8 feature-header">
                    <h1 className="font-bold text-5xl mb-3">
                        Display your top members
                    </h1>
                    <p className="font-semibold text-lg">
                        Create leaderboards that automatically update with the most active texters, chatters, or inviters.
                    </p>
                </div>
            </div>

            <div className="ofeature flex-col sm:flex-row-reverse text-center sm:text-left">
                <div className="w-full sm:w-1/2 p-4 sm:p-8">
                    <img className="rounded-lg shadow shadow-2xl mx-auto" src="/edit.png" />
                </div>
                <div className="w-full sm:w-1/2 sm:p-8 feature-header">
                <h1 className="font-bold text-5xl mb-3">
                    Edit giveaways
                </h1>
                <p className="font-semibold text-lg">
                    Change your giveaways on the fly with an edit menu.
                </p>
                </div>
            </div>

            <div className="feature flex-col sm:flex-row text-center sm:text-left">
                <div className="w-full sm:w-1/2 p-4 sm:p-8">
                    <img className="rounded-lg shadow shadow-2xl mx-auto" src="/logs.png" />
                </div>
                <div className="w-full sm:w-1/2 sm:p-8 feature-header">
                    <h1 className="font-bold text-5xl mb-3">
                        Log everything
                    </h1>
                    <p className="font-semibold text-lg">
                        Create unlimited channels and send events to them so you always know what's up.
                    </p>
                </div>
            </div>
        
            <div className="efeature flex-col">
                <div className="w-full sm:p-8 feature-header">
                    <h1 className="font-bold text-5xl mb-3">
                        High performance
                    </h1>
                    <p className="font-semibold text-lg">
                        You can rely on Dasu's 24/7 uptime. Your giveaways won't run late.
                    </p>
                </div>
        
                <div className="w-full p-4 sm:p-8">
                    <img className="rounded-lg shadow shadow-2xl mx-auto" src="/giveaway.png" />
                </div>
            </div>
        
            <div className="mt-56">
                <h1 className="text-3xl font-black">
                    What's left to ponder?
                </h1>
                <a href="https://discord.com/api/oauth2/authorize?client_id=525748238255390721&permissions=322785&scope=bot%20applications.commands" target="_blank" className="block bg-white text-white font-bold transition duration-200 shadow hover:shadow-2xl ease-in-and-out rounded-lg mx-auto end-button mt-5 px-2 py-4 mb-36 hover:opacity-80 text-xl font-sans w-60">
                    <i className="fas fa-gifts"></i> Start giving
                </a>
            </div>
        </div>
    );
}

export default MainPage;
