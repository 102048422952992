import './App.css';
import MainPage from './components/main';

function App() {
  return (
    <div className="App">
      <MainPage></MainPage>
    </div>
  );
}

export default App;
